import { useNavigate } from "react-router";
import ReFooter from "../../../../common/Footer/screen/ReFooter";
import wing from "../img/pngwing.com.png";
import {
  LoginButtonBoxStyled,
  LoginButtonIntervalStyled,
  LoginButtonStyled,
  LoginDivStyled,
  LoginKaKaoButtonStyled,
  LoginKaKaoWingStyled,
  LoginTextSizeStyled,
  LoginTitleImgStyled,
} from "../css/ReLoginPageStyled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLastMonth,
  setToday,
} from "../../../../common/redux/action/ToDayAction";
import {
  getCurrentDate,
  getLastMonthDate,
} from "../../../../util/DateCalculation";
import emailLogin from "../repo/EmailLogin";
import { isValidEmail } from "../../../../util/const";
import MessageDisplay from "../../../../util/MessageDisplay";
import { getReUrlData } from "../../../CreateButton/Renewal/repo/UrlData";
import { setButtonUrl } from "../../../../common/redux/action/UrlButtonAction";
import { getProfileData } from "../../../Modifying/Renewal/repo/Profile";
import { setProfileData } from "../../../../common/redux/action/ProfileAction";
import { loginInfoDataText } from "../../text/loginText";
import { useTranslation } from "react-i18next";
import { Toggle } from "../../../../util/toggle";
import { setLanguage } from "../../../../common/redux/action/languageAction";
import Paypal from "../../../paymentPage/Paypal";
import PaymentPage from "../../../paymentPage/Paypal";
import { handleProfileData } from "../../components/LoginCommon";
import { API_URL } from "../../../../Repository/URL";
import CoupangAd, { CoupangSrc2 } from "../../../../util/iframe";

const ReLoginPage = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isVerySmallScreen, setIsVerySmallScreen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);

      setIsSmallScreen(window.innerWidth <= 500);
      setIsVerySmallScreen(window.innerWidth <= 420);
    };

    window.addEventListener("resize", handleResize);
    // 초기 화면 크기에 따라 설정
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const languageData = useSelector((state) => state.languageType.type);
  useEffect(() => {
    const languagePrefix = i18n.language.split("-")[0];
    dispatch(setLanguage(languagePrefix));
  }, [i18n.language]);

  const toggleLanguage = () => {
    const newLanguage = ["ko", "ko-KR"].includes(i18n.language) ? "en" : "ko";
    // const newLanguage = i18n.language === "ko" ? "en" : "ko";

    i18n.changeLanguage(newLanguage);
  };

  const [isUserId, setUserId] = useState("");
  const [isPassword, setPassword] = useState("");
  const [isUserIdCheck, setUserIdCheck] = useState(false);
  const [isNoIdPassword, setNoIdPassword] = useState(false);
  const [isNetWorkError, setNetWorkError] = useState(false);

  const Kakao = window.Kakao;
  const initKakao = () => {
    const jsKey = "e3300a732962de06d88af3e686dcadc3";
    // const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
      // console.log(Kakao.isInitialized());
    }
  };
  useEffect(() => {
    initKakao();
  }, []);

  const handleUserId = (e) => {
    setUserId(e.target.value);
  };

  const handleUserPassword = (e) => {
    setPassword(e.target.value);
  };

  const kakaoHandleLogin = async () => {
    const currentDay = getCurrentDate();
    const lastDay = getLastMonthDate();
    dispatch(setLastMonth(lastDay.year, lastDay.month, lastDay.day));
    dispatch(setToday(currentDay.year, currentDay.month, currentDay.day));
    Kakao.Auth.authorize({
      redirectUri: `${API_URL}/kakaoLogin`,
      state: "sendfriend_feed",
      scope: "profile_nickname,account_email,profile_image,phone_number,name",
      nonce: "ceazer",
    });
  };

  const loginHandle = async (id, password) => {
    const currentDay = getCurrentDate();
    const lastDay = getLastMonthDate();

    /// 유효성 검사
    if (!isValidEmail(id)) {
      setUserIdCheck(true);
      return;
    }

    if (isValidEmail(id)) {
      setUserIdCheck(false);
    }

    try {
      const resp = await emailLogin(id, password);

      if (resp === true) {
        const buttonURL = await getReUrlData();
        dispatch(setButtonUrl(buttonURL.data));
        dispatch(setLastMonth(lastDay.year, lastDay.month, lastDay.day));
        dispatch(setToday(currentDay.year, currentDay.month, currentDay.day));

        // const profile = await getProfileData();

        // if (profile === "Fail") {
        //   console.log("프로필 호출 에러", profile);
        //   return;
        // } else {
        //   if (profile === "Fail") {
        //     return;
        //   } else {
        //     dispatch(setProfileData(profile.data));
        //   }

        //   dispatch(setProfileData(profile.data));
        // }

        // navigate("/ReMyPage");

        await handleProfileData(dispatch, navigate);
      } else {
        if (resp.error.status_code === 400) {
          setNoIdPassword(true);
        }
        if (resp.error.status_code === 500) {
          setNetWorkError(true);
        }
        return;
      }
    } catch (error) {
      console.log("로그인 오류", error);
      return;
    }
  };

  const signUpHandle = () => {
    navigate("/ReSignUpPage");
  };

  const findPasswordHandle = () => {
    navigate("/FindPassword");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await loginHandle(isUserId, isPassword);
  };

  return (
    <div
      style={{
        backgroundColor:
          windowWidth >= 1025 ? `rgba(166, 173, 185, 0.3)` : `white`,
      }}
    >
      <div className="">
        <div id="container">
          <article id="">
            <div className="content-body">
              <div className="login-area">
                <div className="cont-area">
                  <div
                    style={{
                      display: isSmallScreen ? `block` : `flex`,
                      justifyContent: `space-between`,
                    }}
                  >
                    <h1 className="title">
                      <img src="/group.svg" alt="icon" />
                      <LoginTitleImgStyled
                        src={
                          languageData === "en"
                            ? "/GroupEn.svg"
                            : "/LogoBlack.svg"
                        }
                        alt=""
                      />
                    </h1>
                    <div
                      style={{
                        display: `flex`,
                        alignItems: `center`,
                        justifyContent: isSmallScreen ? `start` : `end`,
                      }}
                    >
                      <Toggle
                        defaultChecked={false}
                        onChange={toggleLanguage}
                      />
                    </div>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <fieldset>
                      <legend className="sr-only">{loginInfoDataText}</legend>
                      <div className="input-area">
                        <div className="row">
                          <LoginTextSizeStyled>
                            {t("idText")}
                          </LoginTextSizeStyled>
                          <input
                            type="text"
                            id="userId"
                            className="form-control"
                            placeholder={t("idInputPleaseText")}
                            title={t("idInputPleaseText")}
                            value={isUserId}
                            onChange={handleUserId}
                          ></input>
                          <MessageDisplay
                            state={isUserIdCheck}
                            message={t("NoEmailErrorMSG")}
                          />
                        </div>
                        <div className="row">
                          <LoginTextSizeStyled>
                            {t("passwordText")}
                          </LoginTextSizeStyled>
                          <input
                            type="password"
                            id="userId"
                            className="form-control"
                            placeholder={t("passwordInputPleaseText")}
                            title={t("passwordInputPleaseText")}
                            value={isPassword}
                            onChange={handleUserPassword}
                          ></input>
                          <MessageDisplay
                            state={isNoIdPassword}
                            message={t("CheckIdPasswordMSG")}
                          />
                          <MessageDisplay
                            state={isNetWorkError}
                            message={t("NetWorkErrorMSG")}
                          />
                        </div>
                        {/* <div className="mt-lg-5 mt-md-3"> */}
                        {/* <span className="check-item">
                          <label htmlFor="inputKeep"> </label>
                        </span> */}
                        {/* </div> */}
                      </div>
                    </fieldset>
                    <div className="btn-area">
                      {languageData !== "en" ? (
                        <LoginButtonBoxStyled>
                          <LoginKaKaoButtonStyled
                            type="button"
                            className="btn btn-lg btn-kakao btn-block"
                            onClick={() => kakaoHandleLogin()}
                          >
                            <LoginKaKaoWingStyled src={wing} alt="말풍선" />
                            <div
                              style={{
                                fontSize: isVerySmallScreen ? `14px` : `20px`,
                              }}
                            >
                              {t("kakaoLoginText")}
                            </div>
                          </LoginKaKaoButtonStyled>
                          <LoginButtonIntervalStyled />
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary btn-block"
                            style={{
                              fontSize: isVerySmallScreen ? `14px` : `20px`,
                            }}
                          >
                            {t("loginText")}
                          </button>
                        </LoginButtonBoxStyled>
                      ) : (
                        <div
                          style={{
                            justifyContent: `center`,
                            width: `100%`,
                          }}
                        >
                          <div />
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary btn-block"
                            style={{
                              fontSize: isVerySmallScreen ? `14px` : `20px`,
                            }}
                          >
                            {t("loginText")}
                          </button>
                        </div>
                      )}

                      <LoginDivStyled>
                        <LoginButtonStyled
                          style={{ marginRight: `15px` }}
                          onClick={() => findPasswordHandle()}
                        >
                          <strong>{t("ChangeFindPassword")}</strong>
                        </LoginButtonStyled>
                        <LoginButtonStyled onClick={() => signUpHandle()}>
                          <strong>{t("singUpText")}</strong>
                        </LoginButtonStyled>
                      </LoginDivStyled>
                    </div>
                  </form>
                </div>
                <div></div>
                <div
                  style={{
                    marginTop: `20px`,
                    // border: `1px solid black`,
                    width: `100%`,
                    height: `100%`,
                    maxHeight: `150px`,
                    maxWidth: `640px`,
                    borderRadius: `16px`,
                  }}
                >
                  <CoupangAd src={CoupangSrc2} width="100%" height="100%" />
                </div>
              </div>
            </div>
          </article>
          {languageData === "en" ? (
            <ReFooter
              termsLink="/termosfuseCrEn"
              personalInfoLink="/personalInfoEn"
            />
          ) : (
            <ReFooter
              termsLink="/termosfuseCr"
              personalInfoLink="/personalInfo"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReLoginPage;
